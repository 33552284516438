<template>
  <div class="">
    <div >
      <h5 class="container text-muted p-3 font-weight-bold">
        Cotización de plan
      </h5>
    </div>
    <section class="section p-0">
      <div class="container">
        <div class="card-body">
          <b-tabs no-nav-style>
            <b-tab no-body class="p-3">
              <div class="row">
                <div
                  v-for="(tab, idx) in props.tabs"
                  v-bind:key="tab.id"
                  class="col-3"
                  @click="tabSwitch(tab.id)"
                >
                  <a
                    href="javascript:;"
                    class="accounts rounded d-block shadow text-center py-3"
                    :class="{
                      active: selectedTab === tab.id,
                      completed: selectedTabIndex > idx,
                    }"
                    style="padding: 5px"
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <span class="pro-icons h3 text-muted">
                          <i :class="tab.icon"></i>
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <h6
                          v-html="tab.text"
                          class="t-title title text-dark my-0"
                        ></h6>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="row rounded mt-5">
                <!-- <div class="col-md-4 rounded bg-primary "></div> -->
                <div class="col-md-12 rounded">
                  <div class="card border-0 rounded shadow">
                    <div class="card-body">
                      <component v-bind:is="selectedTab"></component>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-md-2"></div> -->
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </section>
    <!-- <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12 d-lg-block d-none">
            <div class="sidebar sticky-bar p-4 rounded shadow">
              <div class="widget">
                <div class="progress-box">
                  <h5 class="title font-weight-bold text-muted mt-2">
                    Progreso de Emisión de Seguro
                  </h5>
                  <div class=" row text-left pl-3 progress-value d-block text-muted h5 ">
                    {{ selectedTabIndex }} / {{props.tabs.length}} pasos
                  </div>
                </div>
              </div>
              <div class="widget">
                <div class="row">
                  <div v-for="(tab, idx) in props.tabs" v-bind:key="tab.id" class="col-6 mt-4 pt-2" @click="tabSwitch(tab.id)">
                    <a href="javascript:;"
                      class="accounts rounded d-block shadow text-center py-3"
                      :class="{
                        active   : selectedTab === tab.id,
                        completed: selectedTabIndex > idx
                      }"
                    >
                      <span class="pro-icons h1 text-muted">
                        <i :class="tab.icon"></i>
                      </span>
                      <h6 v-html="tab.text" class="title text-dark h6 my-0"></h6>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8 col-12">
            <div class="card border-0 rounded shadow">
              <div class="card-body">
                <component v-bind:is="selectedTab"></component>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
  </div>
</template>

<script>
import Vue from "vue";

export default {
  props: {
    props: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      selectedTab: null,
    };
  },

  computed: {
    selectedTabIndex() {
      return (
        this.props.tabs.findIndex((tab) => tab.id === this.selectedTab) + 1
      );
    },
  },

  methods: {
    tabSwitch(id) {
      this.selectedTab = id;
    },
  },

  created() {
    for (const component of this.props.tabs) {
      Vue.component(component.id, () =>
        import(`@/components/${component.path}`)
      );
    }
    this.selectedTab = this.props.initialTab;
  },
};
</script>

<style>
.t-title {
  font-size: 10px;
}

.rowing {
  border: 1px solid black;
}

.completed {
  background-color: #eee;
}

.completed h6,
.completed i {
  color: #aaa !important;
}
</style>